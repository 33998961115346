<script setup>
useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} - AVRillo` : 'AVRillo'
  },
  link: [{ rel: 'icon', type: 'image/png', href: '/images/favicon.png' }],
})
</script>

<template>
  <Suspense>
    <nuxt-layout>
      <nuxt-page />
    </nuxt-layout>
  </Suspense>
</template>
